
.student-personal-info tr td:first-child {
  color: rgb(127, 129, 131);
  text-align: right;
}
.student-personal-info tr td:last-child {
    font-weight: 500;
}
.student-personal-info tr td:nth-child(2) {
    padding: 0px 6px;
}