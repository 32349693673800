@import "top_nav.css" screen and (max-width: 1200px);
@import "base_nav.css" screen and (max-width: 1200px);
@font-face {
  font-family: MaiandraGDBold;
  src: url("/public/asset/fonts/MaiandraGDBold.ttf");
}
.site-info > h1{
  font-family: MaiandraGDBold;
  color: #008000;
  font-size: 30px;
}
.site-info > h6{
  margin-top: -7px;
}
.fixed-top{
  margin-top: -50px;
  transform: translateY(50px);
  transition: transform 1.5s;
}
#topNav {
  overflow: hidden;
}
#topNav ul{
  background-color: var(--nav-bg);
  float: right;
  width: 542px;
  border-radius: 0px 0px 0px 35px;
}
  
#topNav ul li a::after {
  content: "";
  width: 0%;
  height: 2px;
  display: block;
  background-color: var(--under-line-hover-color);
  margin: auto;
  transition: 0.5s;
}
#topNav ul li a:hover::after {
  width: 100%;
}

#topNav ul > *:not(:last-child) {
  margin-left: 25px;
}
#topNav ul li a {
  text-decoration: none;
  color: var(--nav-menu-font-color);
}
#topNav .icon {
  display: none;
}
.address{
 text-align: right;
}

 /* Navigation Menu Section */
 #baseNavToggler{
  display: none;
}
.navigation-menu {
  overflow: hidden;
  background-color: var(--nav-bg);
}
.navigation-menu a {
  float: left;
  display: block;
  color: var(--nav-menu-font-color);
  text-align: center;
  padding: var(--nav-padding);
  text-decoration: none;
  font-size: var(--nav-font-size);
}
.navigation-menu > a, .dropdown-container > .dropbtn{
  transition: all 0.5s;
}

.navigation-menu > a:hover, .dropdown-container > .dropbtn:hover{
  color: var(--nav-font-hover-color);
}

/*Underline Link Hover Start*/
.navigation-menu > a::after,
.dropdown-container > button::after,
.mega-column > a::after {
  content: "";
  width: 0%;
  height: 2px;
  background-color: var(--under-line-hover-color);
  display: block;
  margin: auto;
  transition: 0.5s;
}
.navigation-menu > a:hover::after,
.dropdown-container > button:hover::after {
  width: 100%;
}
/*Underline Link Hover End*/

.dropdown-content > a:hover, .sub-dropdown-content > a:hover,
.sub-dropdown-container button:hover {
  background-color: var(--sub-menu-hover-color);
  color: var(--nav-font-hover-color);
}

.dropdown-container {
  float: left;
}
/* sub-dropdown-container */
.dropdown-container,
.sub-dropdown-container {
  overflow: hidden;
}
.dropbtn {
  font-size: var(--nav-font-size);
  border: none;
  outline: none;
  color: var(--nav-menu-font-color);
  padding: var(--nav-padding);
  background-color: var(--nav-bg);
  margin: 0;
}
.sub-dropdown-container .dropbtn {
  width: 100%;
  text-align: left;
}
.sub-dropdown-container .dropbtn > svg {
  float: right;
}
.dropdown-content,
.mega-dropdown-content {
  visibility: hidden;
  opacity: 0;
  top: 133px;
  position: absolute;
  background-color: var(--nav-bg);
  min-width: 200px;
  box-shadow: var(--menu-content-box-shadow);
  z-index: 5;
}
/* .sub-dropdown-content diffrent property */
.sub-dropdown-content {
  visibility: hidden;
  opacity: 0;
  left: 100%;
  position: absolute;
  background-color: var(--nav-bg);
  min-width: 200px;
  transition: 0.5s;
  box-shadow: var(--menu-content-box-shadow);
}

/* .sub-dropdown-content diffrent property */
.mega-dropdown-content {
  width: 100%;
  left: 0;
}
.dropdown-content a,
.sub-dropdown-content a {
  float: none;
  color: var(--nav-menu-font-color);
  padding: var(--nav-padding);
  text-decoration: none;
  display: block;
  text-align: left;
}
.sub-dropdown-content a {
  float: left;
  width: 100%;
}
.mega-dropdown-content .header {
  background: var(--sub-mob-nav-bg);
  padding: 3px 0px;
  text-align: center;
  color: var(--nav-menu-font-color);
}
.mega-dropdown-content .header > h4{
  margin-bottom: 0 !important;
}
/*Transition Hover Start*/
.dropdown-container:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
  transition: var(--nav-hover-transition);
  top: var(--nav-hover-top);
}
.sub-dropdown-container:hover .sub-dropdown-content {
  visibility: visible;
  opacity: 1;
  margin-top: -38px;
}
.dropdown-container:hover .mega-dropdown-content {
  visibility: visible;
  opacity: 1;
  transition: var(--nav-hover-transition);
  top: var(--nav-hover-top);
}
/*Transition Hover End*/
.mega-row {
  height: 400px;
  overflow: scroll;
}
.mega-column {
  float: left;
  width: 25%;
  padding: 10px;
  color: var(--nav-menu-font-color);
}
.mega-column a {
  float: none;
  color: var(--nav-menu-font-color);
  text-decoration: none;
  padding: 2px 0px;
  text-align: left;
}
.mega-column a:hover {
  color: var(--nav-font-hover-color);
}
.mega-column h5 {
  padding-bottom: 15px;
  color: var(--nav-font-hover-color);
}
.mega-column > a::before {
  content: "♦ ";
}
.mega-column p {
  color: #17cb14;
  font-weight: 500;
}
.mega-column > p:nth-child(n + 1) {
  padding-top: 8px;
}
.mega-column hr {
  margin-bottom: 10px;
  border-top: 0.1px solid;
}
.mega-column > a:hover::after {
  width: 100%;
  margin: 0 !important;
}
.mega-row::after {
  content: "";
  display: table;
  clear: both;
}
.active-menu{
  color: var(--nav-font-hover-color) !important;
  font-weight: bold !important;
  border-bottom: 1px solid var(--under-line-hover-color);
}