:root {
  --web-bg-add-section: #020031;
  --nav-menu-font: Georgia;
  --nav-padding: 9px 14px;
  --nav-bg: #032230;
  --nav-menu-font-color: #e9ecef;
  --nav-font-size: 14px;
  --under-line-hover-color: #ff4500;
  --sub-menu-hover-color: #ff4500;
  --nav-font-hover-color: #fff;
  --nav-hover-transition: top 0.5s;
  --nav-hover-top: 113px;
  --icon-color: #03b2c1;
  --menu-content-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  --light: #fff;
  --off-white: #f6fafd;
  --tomato: #ff4500;

  /* Mobile display property */
  --mob-nav-bg: #0b3b52;
  --sub-mob-nav-bg: #bb3300;
}
* {
  outline: 0;
}
.line-height {
  line-height: 1.8; /* Set your desired line-height value here */
}
p{
  margin: 0 !important;
}
body {
  -webkit-font-smoothing: antialiased;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.row{
  --bs-gutter-x: 0rem !important;
}

.cursor-pointer{
  cursor: pointer;
}
.text-justify{
  text-align: justify;
}
.caption-bg{
   background-color: var(--nav-bg);
}

/* Icon Style Start */
.socials {
  display: flex;
}

.socials .social-item {
  margin: 0 5px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid var(--icon-color);
  color: var(--icon-color);
  text-align: center;
  line-height: 35px;
  transition: all 0.3s;
  text-decoration: none;
}

.socials .social-item:hover{
  background: var(--icon-color);
}
.social-item > svg{
    color: #058b86 !important;
}
.social-item > svg:hover{
  color: #FFFFFF !important;
}

/* Icon Style End */


.carousel-control-prev-icon,
.carousel-control-next-icon {
   width: 3rem;
   height: 3rem;
   border-radius: 3rem;
   background-color: #4fa566;
   border: 10px solid #4fa566;
}
.off-white{
  background-color: var(--off-white);
}

/*** Project Portfolio ***/
.portfolio-inner {
  position: relative;
  overflow: hidden;
}
.portfolio-inner::before,
.portfolio-inner::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(15, 66, 41, .6);
  transition: .5s;
}
.portfolio-inner::after {
  left: auto;
  right: 0;
}
.portfolio-inner:hover::before,
.portfolio-inner:hover::after {
  width: 50%;
}
.portfolio-inner .portfolio-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: .5s;
  z-index: 3;
  opacity: 0;
}
.portfolio-inner:hover .portfolio-text {
  transition-delay: .3s;
  opacity: 1;
}
.portfolio-inner .portfolio-text .btn {
  background: var(--light);
  color: var(--icon-color);
}
.portfolio-inner .portfolio-text .btn:hover {
  background: var(--icon-color);
  color: var(--light);
}

/* Team Start */
/* .team-img .team-social {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(68, 66, 90, 0.8);
  transition: .5s;
  z-index: 1;
  opacity: 0;
}
.team-item:hover .team-social {
  opacity: 1;
}
.team-social a{
  border-color: #fff !important;
  color: white !important;
}
.team-social a:hover{
  color: #212529 !important;
  background-color: #FFFFFF;
  border-color: #fff;
} */


.ant-breadcrumb-separator, .ant-breadcrumb-link{
  color: #FFFFFF !important;
}
