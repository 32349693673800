.footer-container{
    background-color: #032230;
    background-image: url('/public/asset/img/footer/earth.png') ;
    background-position: center 72px;
    background-repeat: no-repeat;
}
.footer-container p{
    font-size: 14px;
    margin-bottom: 0px !important;
}
.footer-container ul li{
  padding: 2px 0px;
}
ul li a::after {
  content: "";
  width: 0%;
  height: 2px;
  background-color: var(--under-line-hover-color);
  display: block;
  transition: 0.7s;
}
ul li a:hover::after {
  width: 50%;
}


.social-icon-container .icon {
  margin: 0px 10px;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.social-icon-container .icon span {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  background-color: var(--icon-color);
  box-shadow: 0px 10px 10px rgba(17, 2, 2, 0.1);
  transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.social-icon-container .tooltip-title {
  position: absolute;
  opacity: 0;
  padding: 10px 18px;
  top: 0;
  background-color: #1094f4;
  z-index: 1;
  color: #fff;
  border-radius: 25px;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  font-family: var(--JosefinSansFont);
}
.social-icon-container .icon:hover .tooltip-title {
  opacity: 1;
  top: -70px;
  pointer-events: auto;
}
.social-icon-container .tooltip-title:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background-color: #1094f4;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.social-icon-container .icon:hover span {
  color: #fff;
}
.social-icon-container .facebook:hover span,
.social-icon-container .facebook:hover .tooltip-title,
.social-icon-container .facebook:hover .tooltip-title:before {
  background-color: #0162c8;
}
.social-icon-container .youtube:hover span,
.social-icon-container .youtube:hover .tooltip-title,
.social-icon-container .youtube:hover .tooltip-title:before {
  background-color: #ff0000;
}
.social-icon-container .twitter:hover span,
.social-icon-container .twitter:hover .tooltip-title,
.social-icon-container .twitter:hover .tooltip-title:before {
  background-color: #1c9cea;
}
.social-icon-container .whatsapp:hover span,
.social-icon-container .whatsapp:hover .tooltip-title,
.social-icon-container .whatsapp:hover .tooltip-title:before {
  background-color: #4ee365;
}
.social-icon-container .email:hover span,
.social-icon-container .email:hover .tooltip-title,
.social-icon-container .email:hover .tooltip-title:before {
  background-color: #ce493b;
}