/* Animation Slider*/
.captionOverlay{
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(0 0 0 / 75%);
    width: 100%;
    opacity: 1;
    font-size: 3vw;
    padding: 12px 0px 30px 0px;
  }
  .captionAnimation{
    font-weight: 700;
    text-align: center;
    font-size: 20px;
    font-family: Hack, sans-serif;
    text-transform: uppercase;
    background: linear-gradient(90deg, #000, #1bff00, orangered);
    letter-spacing: 2px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
    background-size: 80%;
    animation: shine 10s linear infinite;
    position: relative;
  }

  @keyframes shine {
    0%{
      background-position-x: -500%;
    } 
    100%{
    background-position-x: 500%;
  } 
}


/* Responsive  */
  @media screen and (max-width: 1194px) {
    .captionOverlay{
      display: none;
    }
}
  