/* Media Query  */
@media screen and (max-width: 1200px) {
  .container{
      max-width: 100% !important;
  }
  .logo > img{
    width: 100% !important;
    max-width: 50px !important;
    height: auto;
  }
  .address{
   text-align: center !important;
  }
  #baseNavToggler{
    float: right !important;
    display: block !important;
  }
  .navigation-menu a,.dropbtn {
    display: none !important;
  }
  .navigation-menu > a, .dropdown-container > .dropbtn {
      padding: 8px 14px !important;
  }
  .navigation-menu > a:hover::after,
  .dropdown-container > button:hover::after {
    width: 0% !important;
  }
  .dropdown-content,
  .mega-dropdown-content,
  .sub-dropdown-container button {
    background-color: var(--mob-nav-bg) !important;
  }
  .dropdown-content,
  .sub-dropdown-content,
  .mega-dropdown-content {
    display: none !important;
  }
  .dropdown-container:hover .dropdown-content {
    display: block !important;
    top: 0px !important;
  }
  .sub-dropdown-container:hover .sub-dropdown-content {
    display: block !important;
    top: 0px !important;
  }
  .dropdown-container:hover .mega-dropdown-content {
    display: block !important;
    top: 0px !important;
  }
  /* fa fa-angle-right will be left position */
  .sub-dropdown-container .dropbtn i {
    float: none !important;
  }
  .sub-dropdown-content {
    background-color: var(--sub-mob-nav-bg) !important;
    left: 0% !important;
    margin-top: 0px !important;
  }
  .mega-column {
    width: 100% !important;
    height: auto !important;
  }
  .mega-column h5 {
    padding-bottom: 0px !important;
  }
  .mega-column > a:hover::after {
    width: 0% !important;
  }
  .mega-column > a:hover {
    background-color: var(--hover-color) !important;
  }

  /* Responsive Section */
  .navigation-menu.responsive {
    position: relative !important;
  }
  .navigation-menu.responsive #baseNavToggler {
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
  }
  .navigation-menu.responsive a {
    float: none !important;
    display: block !important;
    text-align: left !important;
  }
  .navigation-menu.responsive .dropdown-container,
  .sub-dropdown-container {
    float: none !important;
  }
  .navigation-menu.responsive .dropdown-content,
  .mega-dropdown-content,
  .sub-dropdown-content {
    position: relative !important;
  }
  .navigation-menu.responsive .dropdown-container .dropbtn {
    display: block !important;
    width: 100% !important;
    text-align: left !important;
  }
}
