.text-animation::after{
    content: "";
    width: 0%;
    height: 1px;
    display: block;
    transition: 0.9s;
    background-color: var(--tomato);
    margin: 0 auto;
}
.text-animation:hover::after {
    width: 100%;
}
.text-animation:hover{
    color: var(--tomato) !important;
}