/*** Service ***/
.card-box-item {
    position: relative;
  }
  .card-box-item .card-box-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;
  }
  .card-box-item .card-box-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    object-fit: cover;
    transform: scale(1.2);
    transition: .3s;
    z-index: -1;
  }
  .card-box-item:hover .card-box-img img {
    transform: scale(1);
  }
  .card-box-item .card-box-text {
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
    transition: .3s;
  }
  .card-box-item:hover .card-box-text {
    background: #F1F1F1;
  }
  .card-box-item .card-box-text h4,
  .card-box-item .card-box-text p {
    transition: .3;
  }
  .card-box-item:hover .card-box-text h4 {
    color: #000;
  }
  
  .card-box-item:hover .card-box-text p {
    color: #000;
  }
  .card-box-item .card-box-text .btn-square {
    width: 100px;
    height: 100px;
    background: transparent;
    transition: .5s;
  }
  .card-box-item:hover .card-box-text .btn-square {
    background: #FFFFFF;
  }
  
  .card-box-item .card-box-text .btn {
    width: 31px;
    height: 31px;
    display: inline-flex;
    align-items: center;
    color: #000;
    background: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    transition: .3s;
  }
  .card-box-item:hover .card-box-text .btn {
    width: 112px;
  }
  .truncate {
    display: -webkit-box;
    -webkit-line-clamp: 8; /* Set the desired number of lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }