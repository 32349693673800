.heading-line::before {
    content: "";
    width: 10rem;
    height: 0.1rem;
    display: block;
    margin: 0 auto;
    background-color: var(--under-line-hover-color);
  }
  
  .heading-line::after {
    content: "";
    width: 2rem;
    padding-top: 0.5rem;
    height: 0.2rem;
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
    background-color: var(--under-line-hover-color);
  }