@media screen and (max-width: 576px) {
    .site-info > h1{
        font-size: 4vw !important;
      }
    .site-info > h6{
        font-size: 3vw !important;
        margin-top: 0px !important; 
    }  
    .logo-site-info{
        justify-content: space-evenly !important;
      }
      .logo-site-info sup{
        padding: 2px 0px;
      }
      .address-none{
        display: none;
        transition: all 0.5s;
      }
}

@media screen and (max-width: 1200px) {
    .top-nav {
        width: 100% !important;
        margin-top: 5px;
    }
    #topNav ul{
        float: none;
        width: 100% !important;
        border-radius: 0px 0px 0px 0px !important;
      }
    .top-nav ul li a {
        float: none !important;
        display: none !important;
    }
    .top-nav ul li a.icon {
        color: var(--icon-color) !important;
        float: right !important;
        display: block !important;
    }

    /* Responsive Section */
    .top-nav.responsive {
        position: relative;
    }
    .top-nav.responsive .icon {
        position: absolute;
        top: 0;
        right: 0;
    }
    .top-nav > ul{
        display: block !important;
    }
    .top-nav.responsive ul li a {
        display: block !important;
        text-align: left !important;
    }
}