.card-wrapper {
    position: relative;
    box-shadow: 0 0 4px 0 rgba(54, 68, 75, .1), 0 2px 6px 0 rgba(54, 68, 75, .1);
    transition: all ease-out .3s;
    border-radius: .625em;
    overflow: hidden; /* Added line to prevent scrolling */
  }
  
  .card-wrapper:hover {
    transform: scale(1.10);
    box-shadow: 0 10px 36px 0 rgb(54 68 75 / 18%);
    cursor: pointer;
  }
  

  